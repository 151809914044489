<template>
  <div class="container" v-loading="loading">
    <el-tabs v-model="activeName" @tab-click="onTabClick">
      <el-tab-pane
          label="基本信息"
          name="1"
      >
        <!--  基本信息表-->
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="label">姓名</div>
            <div class="value">{{ basicInfo.name }}</div>
          </el-col>
          <el-col :span="8">
            <div class="label">生日</div>
            <div class="value">{{ basicInfo.age }}</div>
          </el-col>
          <el-col :span="8">
            <div class="label">测评地点</div>
            <div class="value">{{ basicInfo.address }}</div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="label">您的指导医生</div>
            <div class="value">{{ basicInfo.zdys }}</div>
          </el-col>
          <el-col :span="8">
            <div class="label">民族</div>
            <div class="value">{{ basicInfo.mz }}</div>
          </el-col>
          <el-col :span="8">
            <div class="label">是否独生子女</div>
            <div class="value">{{ basicInfo.dszn }}</div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="label">居住条件</div>
            <div class="value">{{ basicInfo.jztj }}</div>
          </el-col>
          <el-col :span="8">
            <div class="label">居住方式</div>
            <div class="value">{{ basicInfo.jzfs }}</div>
          </el-col>
          <el-col :span="8">
            <div class="label">医疗付费方式</div>
            <div class="value">{{ basicInfo.fffs }}</div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="label">文化程度</div>
            <div class="value">{{ basicInfo.whcd }}</div>
          </el-col>
          <el-col :span="8">
            <div class="label">有无宗教信仰</div>
            <div class="value">{{ basicInfo.zjxy }}</div>
          </el-col>
          <el-col :span="8">
            <div class="label">从事职业</div>
            <div class="value">{{ basicInfo.gzzk }}</div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="label">目前工作状况</div>
            <div class="value">{{ basicInfo.gzzt }}</div>
          </el-col>
          <el-col :span="8">
            <div class="label">个人年收入</div>
            <div class="value">{{ basicInfo.grnsr }}</div>
          </el-col>
          <el-col :span="8">
            <div class="label">家庭年收入</div>
            <div class="value">{{ basicInfo.jtnsr }}</div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="label">婚姻状况</div>
            <div class="value">{{ basicInfo.hyzk }}</div>
          </el-col>
          <el-col :span="8">
            <div class="label">你对现在婚姻状态满意度</div>
            <div class="value">{{ basicInfo.hhmyzt }}</div>
          </el-col>
          <el-col :span="8">
            <div class="label">你对婚后婆媳关系状态满意度</div>
            <div class="value">{{ basicInfo.hhpxzt }}</div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="label">末次月经时间</div>
            <div class="value">{{ basicInfo.mcyjsj }}</div>
          </el-col>
          <el-col :span="8">
            <div class="label">当前怀孕胎次</div>
            <div class="value">{{ basicInfo.hyzs }}</div>
          </el-col>
          <el-col :span="8">
            <div class="label">躯体疾病</div>
            <div class="value">{{ basicInfo.qtjb }}</div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="label">不良妊娠</div>
            <div class="value">{{ basicInfo.blrc }}</div>
          </el-col>
        </el-row>
        <!--  基本信息表 end-->
      </el-tab-pane>
      <el-tab-pane
          label="历史报告"
          name="2">
        <template v-for="(item,idx) in reportList" >
          <el-row :gutter="20" v-bind:key="idx">
            <el-col :span="12">
              <div class="label">
                <span>报告生成时间：{{ item.createtime }}</span>
              </div>
              <div class="value" style="display: flex;flex-direction: row">
                <span style="flex: 1">报告结果：{{ item.phq9result }}</span>
                <el-button @click="viewReport(item)" type="primary" plain size="mini">查看报告</el-button>
              </div>
            </el-col>
          </el-row>
        </template>
        <template v-if="reportList.length == 0">
          <el-row :gutter="20">
            <el-col :span="12">
              <div class="label">
                暂无报告
              </div>
            </el-col>
          </el-row>
        </template>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import api from "../../api";
import router from "@/router";


export default {
  name: "UserProfile",
  props: {
    userData: Object,
  },
  data() {
    return {
      loading: true,
      activeName: "1",
      basicInfo: {},
      reportList: [],
    };
  },
  created() {
    this.getCUserProfile();
  },
  methods: {
    onTabClick(tab, event) {
      console.log(tab, event);
    },
    getCUserProfile(){
      api.getCUserProfile({cusercode: this.userData.usercode})
          .then((res) => {
            this.loading = false;
            if (res.data.success === "1") {
              if (res.data.data.basicinfo) {
                this.basicInfo = res.data.data.basicinfo;
              }
              if (res.data.data.reportlist) {
                this.reportList = res.data.data.reportlist;
              }
            } else {
              this.$message({
                showClose: true,
                message: res.data.errorMsg,
                type: "warning",
              });
            }
          })
          .catch(() => {
            this.loading = false;
            this.$message({
              showClose: true,
              message: "请检查网络连接",
              type: "warning",
            });
          });
    },
    viewReport(val) {
      const path = `/echarts/web`;
      let routeUrl = router.resolve({
        path,
        query: {
          reportsid: val.id,
          time: val.createtime,
          email: this.userData.email,
          openid: this.userData.openid,
        },
      });
      window.open(routeUrl.href, "_blank");
    },
  },
};
</script>

<style scoped>
.container {
}
.label {
  line-height: 30px;
  color: #303133;
}
.value {
  line-height: 30px;
  color: #909399;
}
</style>
